@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

:root {
  --main-color: #02867D;
  --light-color: #02867dd8;
  --primary-color: #fff;
  --gray-color: #F3F3F3;
  --dark-color: #474747;
  --gray-light-color: #595959;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/* body {
  zoom: .95
}

*/
/* body.dark{
    --main-color: #e8a70e;
      --light-color: #02867dd8;
      --primary-color: #000;
      --gray-color: #730f0f;
      --dark-color: #474747;
      --gray-light-color: #595959;
} */

img {
  object-fit: cover;
}

a {
  text-decoration: none;
  color: white;
}

.accordion-button:focus,
.form-control:focus,
.navbar-toggler:focus,
.form-check-input:checked,
.form-check-input:focus {
  box-shadow: var(--main-color);
  border-color: var(--main-color);
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--main-color);
  border-color: var(--light-color);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--main-color);
}

.nav-link {
  color: var(--main-color);
}

.nav-link:hover {
  color: var(--light-color);
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: var(--main-color)
}

.dropdown-menu[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (max-width:450px) {
  .dropdown-menu[data-bs-popper] {
    left: 0;
    right: auto;
  }
}
/* 
@media (max-width:600px) {
  body {
    zoom: .8;
    }
}

*/
p {
  margin: 0;
}

input {
  height: 42px;
}

.card {
  min-height: 100%;
}

button {
  color: var(--primary-color);
  border: none;
  outline: none;
  padding: 8px 12px;

}

.btn:hover {
  color: var(--primary-color);
}

.main-btn {
  color: var(--primary-color);
  background-color: var(--main-color);
}

.btn-light {
  color: var(--primary-color);
  background-color: var(--light-color);
}

.main-btn:hover,
.btn-light:hover {
  background-color: var(--main-color);
}

/* plansPage */
.border {
  border: 2px solid var(--main-color) !important
}

.border-light {
  border: 1.5px solid var(--light-color) !important;
}

.card-body-content ul,
.border-bottom {
  border-bottom: 1.5px solid var(--light-color) !important;
}

.scale-12 {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}

/* end plansPage */
.main-color {
  color: var(--main-color);
}

.bg-main-color {
  background-color: var(--main-color);
}

.bg-gray {
  background-color: var(--gray-color);
}

.bg-dark-color {
  background-color: var(--dark-color);
}

.bg-gray-light {
  background-color: var(--gray-light-color);
}

.cursor-pointer {
  cursor: pointer;
}